import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Flex from '../components/Flex';
import Navigation from '../components/Navigation';

const Center = styled(Flex.Col)`
  align-items: center;
`

const NotFoundPage = (props) => {
  const { location } = props;

  return (
    <Layout location={location} seo={{}}>
      <div>
        <Navigation.Landing.Main />
        <Navigation.Landing.Mobile />
        <Center>
          <h1> 404 </h1>
          <h3>
            This page does not exist (yet?)
          </h3>
          <p>
            The cowait documentation is currently under development.
          </p>
        </Center>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
